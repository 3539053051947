import React from "react";

export default ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-shop-honey)">
      <path
        d="M5.96729 2.48267V5.89644C5.96729 6.23936 6.24506 6.51713 6.58797 6.51713C6.93088 6.51713 7.20865 6.23936 7.20865 5.89644C7.20865 5.55353 7.48643 5.27576 7.82934 5.27576C8.17225 5.27576 8.45002 5.55353 8.45002 5.89644V6.2068C8.45002 6.54972 8.7278 6.82749 9.07071 6.82749C9.41363 6.82749 9.6914 6.54972 9.6914 6.2068V2.48267H5.96729Z"
        fill="#FFCD6B"
      />
      <path
        d="M16.2086 1.24146H10.0017V2.48282H16.2086C16.5515 2.48282 16.8293 2.20505 16.8293 1.86214C16.8293 1.51922 16.5512 1.24146 16.2086 1.24146Z"
        fill="#965735"
      />
      <path
        d="M2.55348 8.06909H13.1052C13.6194 8.06909 14.0362 8.48587 14.0362 9.00013C14.0362 9.51436 13.6195 9.93118 13.1052 9.93118H2.55348C2.03925 9.93118 1.62244 9.5144 1.62244 9.00013C1.62247 8.4859 2.03925 8.06909 2.55348 8.06909Z"
        fill="#F0DBC2"
      />
      <path
        d="M12.7949 18.0004H2.86388L1.87729 16.3245C0.644607 14.23 1.05706 11.557 2.86388 9.9314H12.7949C14.6017 11.557 15.0142 14.23 13.7815 16.3245L12.7949 18.0004Z"
        fill="#F0DBC2"
      />
      <path
        d="M7.20865 0H8.45002C9.47851 0 10.3121 0.83359 10.3121 1.86209C10.3121 2.89058 9.47851 3.72417 8.45002 3.72417H7.20865C6.18015 3.72417 5.34656 2.89058 5.34656 1.86209C5.34659 0.83359 6.18018 0 7.20865 0Z"
        fill="#BF7341"
      />
      <path
        d="M14.3109 12.4141H1.34779C0.992748 13.7358 1.18361 15.1448 1.87724 16.3244L2.86383 18.0003H12.7949L13.7815 16.3244C14.4751 15.1448 14.6659 13.7358 14.3109 12.4141Z"
        fill="#FFCD6B"
      />
      <path d="M8.14034 0H7.51965V3.72414H8.14034V0Z" fill="#FFCD6B" />
      <path
        d="M6.27771 0.251248V3.47325C6.46949 3.58434 6.67993 3.65975 6.89839 3.69638V0.0280762C6.67962 0.064709 6.46952 0.140119 6.27771 0.251248Z"
        fill="#FFCD6B"
      />
      <path
        d="M8.76038 0.0280762V3.69635C8.97887 3.65972 9.18928 3.58431 9.38105 3.47321V0.251248C9.18896 0.140119 8.97887 0.064709 8.76038 0.0280762Z"
        fill="#FFCD6B"
      />
      <path
        d="M12.7947 10.2413H4.41534C4.24402 10.2413 4.10498 10.1023 4.10498 9.93097C4.10498 9.75965 4.24402 9.62061 4.41534 9.62061H12.7947C12.966 9.62061 13.105 9.75965 13.105 9.93097C13.105 10.1023 12.966 10.2413 12.7947 10.2413Z"
        fill="#E6CCAF"
      />
      <path
        d="M14.3108 12.4138H11.5177C10.8368 14.9121 9.05944 16.9666 6.6853 18H12.7948L13.7813 16.3242C14.4749 15.1445 14.6658 13.7356 14.3108 12.4138Z"
        fill="#FFB75E"
      />
    </g>
    <defs>
      <clipPath id="clip-shop-honey">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
